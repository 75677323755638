/* eslint-disable max-lines-per-function */
/* eslint-disable no-magic-numbers */
import { ref, reactive, onMounted } from '@vue/composition-api';
import { getUserFilePage } from '@mdm/composition/dc/api/oss';
import { Message } from 'element-ui';
const G = 1024 * 1024 * 1024;
const M = 1024 * 1024;
const K = 1024;

export default function(props, setupContext) {
    onMounted(() => {
        // 初始化表格数据显示
    });

    const pager = reactive({
        currentPage: 1,
        pageTotal: 0,
        pageNo: 1,
        pageSize: 10
    });
    const loading = ref(true);
    const totalData = ref([]);
    let filterParam = reactive({});

    const searchChange = (data) => {
        if (Object.keys(data).length !== 0) {
            pager.pageNo = 1;
        }
        filterParam = data;
        loadData();
    };
    const pageChange = (data) => {
        pager.pageNo = data;
        loadData();
    };
    const sizeChange = (data) => {
        pager.pageSize = data;
        loadData();
    };
    const loadData = (res) => {
        if (res && res.code === '0') {
            Message.success(res.msg);
        }
        loading.value = true;
        const params = {
            pageNo: pager.pageNo,
            pageSize: pager.pageSize,
            orderByType: 'desc',
            orderBy: 'id',
            ...filterParam
        };
        getUserFilePage({ ...params }).then(res => {
            if (res && res.code === '0') {
                res.data.list.forEach((v, k) => {
                    v.fileType = getFileExt(v.localFileName);
                    v.fileSize = getFileSize(v.fileSize);
                    v.fileStatusCode = v.fileStatus;
                    v.fileStatus = getFileStatus(v.fileStatus);
                });
                pager.pageTotal = res.data.totalCount;
                totalData.value = res.data.list;
                loading.value = false;
            }
        }).catch(res => {
            loading.value = false;
        });
    };

    const getFileSize = (fileSize) => {
        let fileSizeString = fileSize;
        if (fileSize > G) {
            fileSizeString = (fileSize / G).toFixed(2) + 'G';
        } else if (fileSize > M) {
            fileSizeString = (fileSize / M).toFixed(2) + 'M';
        } else if (fileSize > K) {
            fileSizeString = (fileSize / K).toFixed(2) + 'K';
        } else if (fileSize < K && fileSize !== null) {
            fileSizeString = fileSize + 'B';
        } else if (fileSize === null) {
            fileSizeString = '';
        }
        return fileSizeString;
    };
    const getFileExt = (fileName) => {
        return fileName && fileName.replace(/^.+\./, '').toLowerCase();
    };
    const ossUrl = ref('');

    const getFileStatus = (fileStatus) => {
        let fileStatusString;
        switch (+fileStatus) {
        case 0:
            fileStatusString = '未知';
            break;
        case 1:
            fileStatusString = '已提交';
            break;
        case 2:
            fileStatusString = '生成中';
            break;
        case 3:
            fileStatusString = '已生成';
            break;
        case 4:
            fileStatusString = '上传失败';
            break;
        case 5:
            fileStatusString = '异常';
            break;
        default:
            fileStatusString = '';
            break;
        }
        return fileStatusString;
    };

    return {
        pager,
        loading,
        totalData,
        searchChange,
        pageChange,
        sizeChange,
        loadData,
        ossUrl
    };
};
