import request from '@/utils/http';

export const saveUserUpload = (params) => {
    return request({
        url: `/api-file/mdmFileInfo`,
        method: 'POST',
        data: params
    });
};

export const getUserFileList = (params) => {
    return request({
        url: `/api-file/mdmFileInfos`,
        method: 'GET',
        params: params
    });
};

export const getUserFile = (params) => {
    return request({
        url: `/api-file/mdmFileInfo/${params.id}`,
        method: 'GET'
    });
};

export const getDownloadFile = (params) => {
    return request({
        url: `/api-file/mdmFileStorage/export/${params.id}`,
        method: 'GET'
    });
};

export const getUserFilePage = (params) => { // OSS用户文件分页查询
    return request({
        url: `/api-oss/oss/file/info/page`,
        method: 'GET',
        params: params
    });
};
